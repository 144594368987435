import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'

class LegalPageTemplate extends React.Component {

  render() {
    const {
      content,
      contentComponent,
      title,
      helmet,
    } = this.props;
    const PostContent = contentComponent || Content

    return (
      <section>
        {helmet || ''}
        <div className="page-content">
          <div>{title}</div>
          <div className="user-content">
            <PostContent content={content}/>
          </div>
        </div>
      </section>
    )
  }
}

LegalPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const LegalPage = ({data}) => {
  const {markdownRemark: post} = data
  return (
    <Layout backButton>
      <LegalPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content="Legal information"
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
